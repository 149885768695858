import { useEffect, useState } from 'react'
import { css } from '@emotion/css'
import { IonContent, IonHeader, IonModal, IonButton } from '@ionic/react'
import Header from './Header'
import storage from '../lib/storage'
import { registerForPushNotifications } from '../lib/hooks/push-notification'

const list = css`
  margin-bottom: 30px;
  max-width: 200px;
  margin: 2rem auto 0;
  padding: 0;
  text-align: left;
  list-style: none;

  li {
    text-align: center;
    font-weight: 500;

    + li {
      margin-top: 1.5rem;
    }

    img {
      display: block;
      width: 30px;
      padding-bottom: 5px;
      margin: 0 auto;

      &.icon-voucher {
        width: 35px;
      }
    }
  }
`

const WelcomeModal: React.FC<{ isOpenInitially: boolean }> = ({
  isOpenInitially,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(isOpenInitially)
  }, [isOpenInitially])

  async function enableNotifications() {
    const status = await registerForPushNotifications()
    storage.setNotificationsAccepted(status)
    await hideModal()
  }

  async function disableNotifications() {
    storage.setNotificationsAccepted(false)
    await hideModal()
  }

  async function hideModal() {
    await storage.markWelcomeAsRead()
    setIsOpen(false)
  }

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <Header logo={true} user={false}></Header>
      </IonHeader>
      <IonContent
        className={css`
          text-align: center;
        `}
      >
        <div className="ion-padding-horizontal ion-padding-top">
          <h1
            className={css`
              margin-top: 2rem !important;
              margin-bottom: 2rem !important;
              line-height: 1.4;
              font-size: 1.4rem;
              font-weight: 400;
            `}
          >
            Willkommen zur <br />
            Mode Jost App
          </h1>

          <p
            className={css`
              padding: 0 4rem;
              margin: 0 auto;
            `}
          >
            Aktivieren Sie Benachrichtigungen und erhalten Sie:
          </p>
          <ul className={list}>
            <li>
              <img src="assets/icon/voucher.svg" alt="Gutscheine" />
              Bonus- und Geburtstagsgutscheine
            </li>
            <li>
              <img src="assets/icon/sale.svg" alt="Angebote" />
              Persönliche Angebote
            </li>
            <li>
              <img src="assets/icon/mail.svg" alt="Einladungen" />
              Einladungen zu exklusiven Veranstaltungen
            </li>
          </ul>
        </div>

        <div className="mt-2 ion-padding-horizontal">
          <IonButton expand="block" onClick={() => enableNotifications()}>
            Benachrichtigungen aktivieren
          </IonButton>
          <IonButton
            className={css`
              text-decoration: none;
              color: var(--ion-color-tertiary);
              font-size: 14px;
            `}
            fill="clear"
            onClick={() => disableNotifications()}
          >
            Keine Benachrichtigungen
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default WelcomeModal
