import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
} from '@ionic/react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { VoucherBadge } from '../components/VoucherBadge'

const MyJostPage: React.FC = () => {
  return (
    <>
      <Menu />
      <IonPage id="main-content">
        <IonHeader>
          <Header menu={true} back={false}>
            myJost
          </Header>
        </IonHeader>
        <IonContent fullscreen>
          <img src="/assets/images/jost-header.jpg" alt="" />
          <IonList lines="full">
            <IonItem routerLink="/myjost/bonuspoints">
              <IonLabel>Meine Bonuspunkte</IonLabel>
            </IonItem>
            <IonItem routerLink="/myjost/receipts">
              <IonLabel>Meine Einkäufe</IonLabel>
            </IonItem>
            <IonItem routerLink="/myjost/trials">
              <IonLabel>Offene Auswahlen</IonLabel>
            </IonItem>
            <IonItem routerLink="/myjost/vouchers">
              <IonLabel>Meine Gutscheine</IonLabel>
              <VoucherBadge />
            </IonItem>
            <IonItem routerLink="/myjost/mobilepay">
              <IonLabel>Mein MobilePAY</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonPage>
    </>
  )
}

export default MyJostPage
