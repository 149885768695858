import {
  useIonModal,
  IonIcon,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
} from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'
import { css } from '@emotion/css'
import { API, Receipt, ReceiptPosition } from '../lib/api'
import { InnerMessage } from '../components/Content'
import { toDate, toEuro } from '../lib/utils'
import { mobilepayWithdrawalDate } from '../lib/mobilepay'
import Barcode from './Barcode'

export const ReceiptList: React.FC<{
  receipts: Receipt[]
  sum?: boolean
  withdrawalDate?: boolean
  itemLink: (receipt: Receipt) => string
  notFoundMessage?: string
}> = ({
  receipts,
  itemLink,
  sum = false,
  withdrawalDate = false,
  notFoundMessage = 'Für den ausgewählten Zeitraum liegen keine Belege vor.',
}) => {
  const sumPrice = (receipts: Receipt[]) => {
    return receipts.reduce((sum, receipt: Receipt) => {
      return sum + receipt.totalPrice
    }, 0)
  }

  return (
    <>
      {receipts.length > 0 ? (
        <IonList lines="full">
          {receipts.map((receipt) => (
            <IonItem routerLink={itemLink(receipt)} key={receipt.receiptNumber}>
              <div slot="start">
                {toDate(
                  withdrawalDate
                    ? mobilepayWithdrawalDate(receipt.date)
                    : receipt.date
                )}
              </div>
              <div slot="end">{toEuro(receipt.totalPrice)}</div>
            </IonItem>
          ))}
          {sum && (
            <IonItem
              detail={true}
              className={css`
                ::part(detail-icon) {
                  visibility: hidden;
                }
              `}
            >
              <div slot="start">Summe</div>
              <div slot="end">{toEuro(sumPrice(receipts))}</div>
            </IonItem>
          )}
        </IonList>
      ) : (
        <InnerMessage>{notFoundMessage}</InnerMessage>
      )}
    </>
  )
}

interface ReceiptDetailFields {
  receiptNumber: boolean
  date: boolean
  time: boolean
  totalPrice: boolean
  totalQuantity: boolean
  payment: boolean
  staff: boolean
}

export const ReceiptDetail: React.FC<{
  receipt: Receipt
  label: string
  fields?: Partial<ReceiptDetailFields>
  barcode?: boolean
}> = ({ receipt, label, fields: customFields = {}, barcode = false }) => {
  const fields: ReceiptDetailFields = {
    receiptNumber: true,
    date: true,
    time: false,
    totalPrice: true,
    totalQuantity: false,
    payment: false,
    staff: false,
    ...customFields,
  } as ReceiptDetailFields

  const styles = css`
    .total {
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;

      td {
        padding: 6px 0;
        text-transform: uppercase;
      }

      .value {
        text-align: right;
        padding-right: 10px;
      }
    }
  `

  return (
    <>
      <div>
        <table className={`table ${styles}`}>
          <tbody>
            {receipt.positions.map((position) => (
              <ReceiptPositionLine
                position={position}
                key={position.receiptPosition}
              />
            ))}
            <tr className="total">
              <td colSpan={3}>Gesamt</td>
              <td className="value">{toEuro(receipt.totalPrice)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-1">
        <table className="table col-2">
          <tbody>
            {fields.receiptNumber && (
              <tr>
                <td className="label">{label}</td>
                <td>{receipt.receiptNumber}</td>
              </tr>
            )}
            {fields.date && (
              <tr>
                <td className="label">Datum</td>
                <td>{toDate(receipt.date, 'DD.MM.YYYY')}</td>
              </tr>
            )}
            {fields.time && (
              <tr>
                <td className="label">Uhrzeit</td>
                <td>{receipt.time}</td>
              </tr>
            )}
            {fields.totalPrice && (
              <tr>
                <td className="label">Summe</td>
                <td>{toEuro(receipt.totalPrice)}</td>
              </tr>
            )}
            {fields.totalQuantity && (
              <tr>
                <td className="label">Anzahl Artikel</td>
                <td>{receipt.totalQuantity}</td>
              </tr>
            )}
            {fields.payment && (
              <tr>
                <td className="label">Zahlungsart</td>
                <td>{receipt.payment}</td>
              </tr>
            )}
            {fields.staff && (
              <tr>
                <td className="label">Modeberater</td>
                <td>{receipt.staff}</td>
              </tr>
            )}
          </tbody>
        </table>
        {barcode && (
          <div className="mt-2">
            <Barcode value={String(receipt.receiptNumber)} />
          </div>
        )}
      </div>
    </>
  )
}

export const ReceiptPositionLine: React.FC<{ position: ReceiptPosition }> = ({
  position,
}) => {
  const [present, dismiss] = useIonModal(ReceiptPositionModal, {
    position,
    onDismiss: () => dismiss(),
  })

  const styles = css`
    td {
      vertical-align: middle;
      padding: 2px 0;

      .discount {
        font-style: italic;
      }
    }

    .amount {
      width: 5%;
      font-size: 0.8rem;
    }

    .image {
      width: 15%;
      padding-right: 5px;
      text-align: left;
    }

    .title span {
      border-bottom: 1px dotted var(--ion-color-primary);
    }

    .value {
      text-align: right;
      padding-right: 10px;
    }
  `

  return (
    <tr onClick={() => present()} className={styles}>
      <td className="amount">{position.quantity}×</td>
      <td className="image">
        <img src={productImage(position.ean)} alt="" />
      </td>
      <td className="title">
        <span>{position.name}</span>
        {position.priceDeduction > 0 && (
          <div className="discount">
            Artikelrabatt {position.priceDiscount?.toFixed()}%
          </div>
        )}
      </td>
      <td className="value">
        {toEuro(position.originalPrice)}
        {position.priceDeduction > 0 && (
          <div className="discount">-{toEuro(position.priceDeduction)}</div>
        )}
      </td>
      <td>
        <IonIcon color="primary" icon={chevronForwardOutline}></IonIcon>
      </td>
    </tr>
  )
}

const ReceiptPositionModal: React.FC<{
  position: ReceiptPosition
  onDismiss: () => void
}> = ({ position, onDismiss }) => (
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton onClick={onDismiss}>Schließen</IonButton>
        </IonButtons>
        <IonTitle>{position && position.name}</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent class="ion-padding">
      <>
        <table className="table col-2">
          <tbody>
            <tr>
              <td className="label">Artikel-Nr.</td>
              <td>{position.supplierArticleNumber}</td>
            </tr>
            <tr>
              <td className="label">Beschreibung</td>
              <td>{position.name}</td>
            </tr>
            <tr>
              <td className="label">Größe</td>
              <td>{position.size}</td>
            </tr>
            <tr>
              <td className="label">Menge</td>
              <td>{position.quantity}</td>
            </tr>
            {position.priceDiscount > 0 && (
              <tr>
                <td className="label">Originalpreis</td>
                <td className="original-price">
                  {toEuro(position.originalPrice)}
                </td>
              </tr>
            )}
            {position.priceDiscount > 0 && (
              <tr>
                <td className="label">Preis</td>
                <td>{toEuro(position.salePrice)}</td>
              </tr>
            )}
            {position.priceDiscount > 0 && (
              <tr>
                <td className="label">Artikelrabatt</td>
                <td>{toEuro(position.priceDeduction)}</td>
              </tr>
            )}
            {position.priceDiscount > 0 && (
              <tr>
                <td className="label">Prozent</td>
                <td>{position.priceDiscount?.toFixed()}%</td>
              </tr>
            )}
          </tbody>
        </table>

        <div>
          <img src={productImage(position.ean)} alt={`${position.ean}`} />
        </div>
      </>
    </IonContent>
  </IonPage>
)

function productImage(ean: number) {
  return `${API}/fashioncloud/products/image/${ean}`
}
