import { css } from '@emotion/css'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import Barcode from '../components/Barcode'
import { RemoteContent } from '../components/Content'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { getMe, Customer } from '../lib/api'
import { useRemote } from '../lib/hooks/remote'

const BarcodePage: React.FC = () => {
  const { data: customer, error, fetch } = useRemote<Customer>(getMe)

  return (
    <>
      <Menu />
      <IonPage id="main-content">
        <IonHeader>
          <Header menu={true} back={false}>
            Kundenkarte
          </Header>
        </IonHeader>
        <IonContent fullscreen className="ion-padding">
          <RemoteContent onRetry={fetch} error={error}>
            {customer && (
              <>
                <div
                  className={css`
                    margin-top: 1rem;

                    @media (min-height: 610px) {
                      margin-top: 3rem;
                    }
                  `}
                >
                  <Barcode value={customer.customerCardNumber} />
                </div>

                <div
                  className={css`
                    padding: var(--ion-padding);
                    margin: 1rem 0;

                    @media (min-height: 610px) {
                      margin: 3rem 0;
                    }

                    .label {
                      width: 50% !important;
                      text-transform: none !important;

                      @media (min-width: 375px) {
                        width: 45% !important;
                      }
                    }
                  `}
                >
                  <table className="table col-2">
                    <tbody>
                      <tr>
                        <td className="label">Name</td>
                        <td>{customer.name1}</td>
                      </tr>
                      <tr>
                        <td className="label">Kundennr.</td>
                        <td>{customer.number}</td>
                      </tr>
                      <tr>
                        <td className="label">Auswahlservice</td>
                        <td>
                          {customer.mobilepayEnabled ? 'aktiv' : 'inaktiv'}
                        </td>
                      </tr>
                      <tr>
                        <td className="label">MobilePAY</td>
                        <td>
                          {customer.mobilepayEnabled ? 'aktiv' : 'inaktiv'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </RemoteContent>
          <div
            className={css`
              position: absolute;
              left: 0;
              right: 0;
              bottom: 80px;

              img {
                display: block;
                width: 200px;
                margin: 0 auto;
                padding: 1rem;
                display: block;
              }
            `}
          >
            <img src="/assets/images/jost-slogan.png" alt="Jost zieht an" />
          </div>
        </IonContent>
      </IonPage>
    </>
  )
}

export default BarcodePage
