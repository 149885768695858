import { IonContent, IonHeader, IonPage } from '@ionic/react'
import Header from '../components/Header'

const Placeholder: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <Header logo={true} user={false}></Header>
      </IonHeader>
      <IonContent fullscreen />
    </IonPage>
  )
}

export default Placeholder
