import { useCallback, useEffect, useRef, useState } from 'react'
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
} from '@ionic/react'
import { getPagesCached, Page } from '../lib/api'
import { useHistory } from 'react-router-dom'

const Menu: React.FC<{}> = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menu = useRef<any>(null)
  const history = useHistory()
  const [pages, setPages] = useState<Page[]>([])

  const fetch = useCallback(async () => {
    const pages = await getPagesCached()
    const menuPages = pages.filter((p) => p.menu)
    setPages(menuPages)
  }, [])

  useEffect(() => {
    fetch()
  }, [fetch])

  useEffect(() => {
    const unlisten = history.listen(() => {
      menu.current.close()
    })

    return unlisten
  })

  return (
    <IonMenu side="start" content-id="main-content" ref={menu}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Mehr</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {pages.map((page: Page) => (
            <IonItem key={page.title} routerLink={`/pages/${page.slug}`}>
              <IonLabel>{page.title}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  )
}

export default Menu
