import { css } from '@emotion/css'
import { IonButton, IonSpinner } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useAuth } from '../lib/providers/auth'
import { useLoading } from '../lib/providers/loading'

export const ContactSupport: React.FC<{
  error?: { code: string } | null
  action?: string
  review?: boolean
}> = ({ error, action = 'der Vorgang', review = false } = {}) => {
  return (
    <>
      {review && (
        <>
          Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.&nbsp;
        </>
      )}
      Sollte {action} erneut fehlschlagen, wenden Sie sich bitte an Tel.&nbsp;
      <a href="tel:+4963599520">06359/952-0</a> oder&nbsp;
      <a href="mailto:jostapp@mode-jost.de">jostapp@mode-jost.de</a>
      {error?.code && (
        <span
          className={css`
            font-style: italic;
          `}
        >
          {' '}
          (Fehlercode {error.code}).
        </span>
      )}
    </>
  )
}

export const MobilepayDisabled: React.FC = () => {
  return (
    <>
      <div
        className={css`
          h1 {
            color: inherit;
            text-transform: none;
          }
        `}
      >
        <h1>
          So können Sie <strong>MobilePAY</strong> sicher, einfach und bequem
          nutzen:
        </h1>
      </div>
      <div>
        <p>
          Sie zeigen Ihre JOST App an der Kasse vor. Der Kaufbetrag wird Ihnen
          erst nach 10 Tagen von Ihrem Konto abgebucht. Ohne weitere Kosten oder
          versteckte Gebühren!
          <strong>MobilePAY</strong> ist ein exklusiver Service nur für JOST App
          Nutzer.
        </p>
        <p>
          Um diesen Service nutzen zu können, benötigen wir Ihre Bankverbindung.
          Anträge dafür erhalten Sie an jeder Kasse oder unter
          <a href="https://www.mode-jost.de/kundenservice/jost-app/">
            www.mode-jost.de
          </a>
        </p>
      </div>
    </>
  )
}

export const InnerMessage: React.FC<{ center?: boolean }> = ({
  center = false,
  children,
}) => {
  return (
    <div
      className={css`
        width: 90%;
        margin: 0 auto;
        text-align: center;
        color: var(--ion-color-medium-tint);
        ${center
          ? css`
              margin-top: 40vh;
            `
          : ''}
      `}
    >
      {children}
    </div>
  )
}

export const RemoteContent: React.FC<{
  onRetry: () => void
  error?: { code: string; status: number } | null
}> = ({ onRetry, error: remoteError, children }) => {
  const auth = useAuth()
  const [{ success, error, loading }] = useLoading()
  const isUnauthorized = remoteError && remoteError.status === 401

  const onLogout = () => {
    auth.logout()
  }

  return (
    <>
      {loading && (
        <div
          className={css`
            display: flex;
            justify-content: center;
            padding: 50px 0;
          `}
        >
          <IonSpinner name="lines" />
        </div>
      )}

      {success && children}

      {error && (
        <div
          className={css`
            width: 80%;
            padding: var(--ion-padding) 0;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--ion-color-medium);

            .details > * {
              margin-top: 1rem;
            }
          `}
        >
          <div className="details">
            <p>Es ist ein Fehler beim Abrufen der Daten aufgetreten.</p>
            <IonButton
              onClick={isUnauthorized ? onLogout : onRetry}
              fill="outline"
              expand="block"
              className="secondary"
            >
              {isUnauthorized ? 'Neu anmelden' : 'Erneut versuchen'}
            </IonButton>
            <small>
              <ContactSupport action="der Ladevorgang" error={remoteError} />
            </small>
          </div>
        </div>
      )}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RemoteButton: React.FC<any> = (props) => {
  const [{ loading }] = useLoading()
  const [showLoading, setShowLoading] = useState<boolean>(false)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (loading) {
      timeoutId = setTimeout(() => setShowLoading(true), 500)
    } else {
      setShowLoading(false)
    }

    return function () {
      clearTimeout(timeoutId)
    }
  }, [loading])

  return (
    <IonButton {...props}>
      {props.children}
      <IonSpinner
        hidden={!showLoading}
        name="dots"
        className={css`
          position: absolute;
          right: 10px;
          margin-left: 10px;
        `}
      />
    </IonButton>
  )
}
