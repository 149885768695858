import { IonPage, IonHeader, IonContent, IonButton } from '@ionic/react'
import { SubmitErrorHandler, SubmitHandler } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import toObject from 'dayjs/plugin/toObject'
import Header from '../components/Header'
import { Warning } from '../components/Callout'
import { ContactSupport, RemoteContent } from '../components/Content'
import CustomerForm, { CustomerInputs } from '../components/CustomerForm'
import { getMe, patchMe, Customer, errorFromResponse } from '../lib/api'
import { useRemote } from '../lib/hooks/remote'
import { useLoading } from '../lib/providers/loading'
import { useAlert } from '../lib/hooks/alert'

dayjs.extend(toObject)

interface SubmitError {
  code: string
  message: string
}

export const ProfilePage: React.FC = () => {
  const { data: customer, fetch, error: getError } = useRemote<Customer>(getMe)
  const history = useHistory()
  const [alert] = useAlert()
  const [submitError, setSubmitError] = useState<SubmitError | null>(null)
  const [, { start, stop }] = useLoading()

  const [customerInputs, setCustomerInputs] = useState<CustomerInputs | null>(
    null
  )

  const onSubmit: SubmitHandler<CustomerInputs> = async (data) => {
    const res = await patchMe({
      // TODO: Use consistent type for number (currently string)
      customer: { ...data, number: Number(data.number) },
    })

    start()

    if (res.ok) {
      stop({ success: true })
      history.push('/', { direction: 'back' })
      alert('Änderungswunsch übermittelt. Bitte E-Mail bestätigen.')
    } else {
      const error = await errorFromResponse(res)
      setSubmitError({
        code: error.errno ? `${error.errno}/${error.code}` : error.code,
        message: `Fehler ${error.code}`,
      })
      stop({ success: false })
      console.error(error)
    }
  }

  const onError: SubmitErrorHandler<CustomerInputs> = (errors) => {
    console.error(errors)
  }

  useEffect(() => {
    if (!customer) {
      return
    }

    const {
      years: birthYear,
      months: birthMonth,
      date: birthDay,
    } = dayjs(customer.dateOfBirth).toObject()

    setCustomerInputs({
      number: String(customer.number),
      firstName: customer.firstName,
      lastName: customer.lastName,
      street: customer.street,
      zipCode: customer.zipCode,
      city: customer.city,
      telephone: customer.telephone,
      mobilePhone: customer.mobilePhone,
      email: customer.email,
      birthYear: birthYear.toString(),
      birthMonth: (birthMonth + 1).toString(),
      birthDay: birthDay.toString(),
      advertisement: customer.subscriptionNewsletter,
    })
  }, [customer])

  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <Header>Persönliche Daten</Header>
        </IonHeader>
        <IonContent fullscreen>
          {!submitError && (
            <RemoteContent onRetry={fetch} error={getError}></RemoteContent>
          )}
          {submitError && (
            <div className="ion-padding-top ion-padding-horizontal">
              <Warning>{submitError.message}</Warning>
              <p>
                <small>
                  <ContactSupport
                    action="die Änderung der persönlichen Daten"
                    review={submitError.code !== 'EREMOTE'}
                    error={submitError}
                  />
                </small>
              </p>
            </div>
          )}
          {customerInputs && (
            <>
              <CustomerForm
                values={customerInputs}
                onSubmit={onSubmit}
                onError={onError}
                fields={{
                  number: 'disabled',
                  firstName: 'disabled',
                  lastName: true,
                  street: true,
                  zipCode: true,
                  city: true,
                  telephone: true,
                  mobilePhone: true,
                  email: 'disabled',
                  birthDate: 'disabled',
                  advertisement: true,
                }}
              >
                <IonButton
                  expand="block"
                  fill="outline"
                  type="submit"
                  className="secondary mt-2"
                >
                  Weiter
                </IonButton>
              </CustomerForm>
            </>
          )}
        </IonContent>
      </IonPage>
    </>
  )
}

export default ProfilePage
