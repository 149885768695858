import React from 'react'
import { IonBadge, IonText } from '@ionic/react'
import { useUserConfig } from '../lib/providers/userConfig'

export const VoucherBadge: React.FC<{}> = () => {
  const { userConfig } = useUserConfig()
  const config = userConfig.vouchers

  return (
    <>
      {!!config.count && (
        <IonBadge color={config.unread ? 'secondary' : 'light'}>
          <IonText color={config.unread ? undefined : 'primary'}>
            {config.count}
          </IonText>
        </IonBadge>
      )}
    </>
  )
}
