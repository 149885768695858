import { IonPage, IonHeader, IonContent } from '@ionic/react'
import { useCallback, useEffect, useState } from 'react'
import { useUserConfig } from '../../lib/providers/userConfig'
import { getMe } from '../../lib/api'
import Header from '../../components/Header'
import { RemoteContent } from '../../components/Content'
import { useRemote } from '../../lib/hooks/remote'
import { css } from '@emotion/css'
import { toEuro } from '../../lib/utils'
import { Link } from 'react-router-dom'

const BonuspointsMyJostPage: React.FC = () => {
  const [totalPoints, setTotalPoints] = useState<number>(0)
  const [points, setPoints] = useState<number>(0)
  const [cents, setCents] = useState<number>(0)

  const { userConfig } = useUserConfig()

  const {
    data: customer,
    error,
    fetch,
  } = useRemote<{ loyalityPoints: number }>(getMe)

  useEffect(() => {
    if (customer) {
      setTotalPoints(customer['loyalityPoints'])
    }
  }, [customer])

  useCountTo(totalPoints, setPoints)
  useCountTo(availableCents(totalPoints), setCents)

  return (
    <IonPage>
      <IonHeader>
        <Header>Bonuspunkte</Header>
      </IonHeader>
      <IonContent fullscreen id="myjost-bonuspoints" className="ion-padding">
        <RemoteContent onRetry={fetch} error={error}>
          <div
            className={css`
              display: flex;
              flex-wrap: wrap;

              h2 {
                width: 50%;
                margin-bottom: 5px;
                font-variant-numeric: proportional-nums;
                font-feature-settings: 'pnum';
                font-size: 4rem;
                text-align: right;
                white-space: nowrap;
              }

              .caption {
                align-self: center;
                padding-left: 0.5rem;
                color: var(--ion-color-primary);
                font-size: 2rem;
                font-weight: 200;
                text-transform: uppercase;
              }
            `}
          >
            <h2>{points}</h2>
            <div className="caption">Punkte</div>
          </div>
          <div>
            <p>
              Ihre Bonuspunkte entsprechen:&nbsp;
              <strong>{toEuro(cents / 100)}</strong>.
            </p>
            {userConfig.vouchers.upcoming && (
              <p
                className={css`
                  background-color: var(--ion-color-light);
                  padding: 20px;
                `}
              >
                Bonuspunkte vermisst? Ihre Bonuspunkte wurden kürzlich in einen
                neuen Gutschein umgewandelt. Diesen finden Sie innerhalb der
                nächsten Wochen unter&nbsp;
                <Link to="/myjost/vouchers">Meine Gutscheine</Link>. Sie
                erhalten eine Push-Benachrichtigung, sobald der Gutschein zur
                Verfügung steht.
              </p>
            )}
            <p>
              Beim Einkauf mit der Jost Kundenkarte erhalten Sie 5 Bonuspunkte
              für jeden Euro Umsatz – auch auf reduzierte Ware. Wir schicken
              Ihnen regelmäßig einen Bonusscheck über die angesparte Summe zu.
              500 Punkte enstprechen dabei einem Gegenwert von einem Euro. So
              wird das Einkaufen bei Jost noch günstiger für Sie!
            </p>
            <p>
              Ihre Bonuspunkte werden zwei mal im Jahr automatisch in einen
              Gutschein umgewandelt. Den Gutschein finden Sie in dieser App
              unter&nbsp;
              <Link to="/myjost/vouchers">Meine Gutscheine</Link>.
            </p>
          </div>
        </RemoteContent>
      </IonContent>
    </IonPage>
  )
}

function availableCents(availablePoints: number): number {
  const pointsToCentRatio = 0.2
  if (!availablePoints) return 0
  return availablePoints * pointsToCentRatio
}

function useCountTo(total: number, setter: (value: number) => void) {
  function incrementer(total: number, duration = 3000) {
    const fps = 60
    const frames = duration / fps
    const increment = Math.floor(total / frames)

    let current = 0
    return () => {
      current = Math.min(total, current + increment)
      return { current, total }
    }
  }

  const animate = useCallback(
    (
      incrementer: () => { current: number; total: number },
      setter: (value: number) => void
    ) => {
      return requestAnimationFrame(() => {
        const { current, total } = incrementer()
        setter(current)

        if (current === total) {
          return
        }

        animate(incrementer, setter)
      })
    },
    []
  )

  return useEffect(() => {
    const animationFrameId = animate(incrementer(total), setter)
    return () => {
      cancelAnimationFrame(animationFrameId)
    }
  }, [animate, setter, total])
}

export default BonuspointsMyJostPage
