import memoizee from 'memoizee'
import storage from './storage'

export const API = process.env['REACT_APP_API'] || '/api'
const CACHE_TIMEOUT = 60 * 1000

export async function postActivate(
  clientId: string,
  data: {}
): Promise<Response> {
  return post(`${API}/user/${clientId}`, data)
}

export async function postResetPassword(
  clientId: string,
  data: {}
): Promise<Response> {
  return post(`${API}/user/${clientId}/reset`, data)
}

export async function postToken(clientId: string, token: string) {
  return postAuthorized(`${API}/user/${clientId}/token`, { token })
}

export async function getLogin(clientId: string, password: string) {
  try {
    return get(`${API}/user/login`, {
      headers: {
        Authorization: 'Basic ' + window.btoa(`${clientId}:${password}`),
      },
    })
  } catch (err) {
    console.error(err)

    // Handle network error
    return new Response(
      JSON.stringify({ code: 'ENET', message: 'Network error' }),
      {
        status: 0,
      }
    )
  }
}

export async function getConfig(): Promise<UserConfig> {
  return getAuthorized(`${API}/user/config`)
    .then(handleJsonResponse)
    .catch(handleError)
}

export const getConfigCached = memoizee(getConfig, {
  promise: true,
  maxAge: CACHE_TIMEOUT,
})

export async function postVouchersRead(): Promise<{}> {
  // Make sure not to return stale entry from cache
  await getConfigCached.clear()

  return postAuthorized(`${API}/user/vouchers/read`).catch(handleError)
}

export async function getMe(): Promise<Customer> {
  return getAuthorized(`${API}/prosync/customer/me`)
    .then(handleJsonResponse)
    .then((attributes) => new Customer(attributes))
    .catch(handleError)
}

export async function patchMe(data: {}): Promise<Response> {
  return postAuthorized(`${API}/prosync/customer/me`, data, { method: 'PATCH' })
}

export async function getPages(): Promise<Page[]> {
  return getAuthorized(`${API}/jost/pages`)
    .then(handleJsonResponse)
    .catch(handleError)
}

export const getPagesCached = memoizee(getPages, {
  promise: true,
  maxAge: CACHE_TIMEOUT,
})

export async function getNews(): Promise<News[]> {
  return getAuthorized(`${API}/jost/news`)
    .then(handleJsonResponse)
    .then((data) => data['news'])
    .catch(handleError)
}

export async function getEvents(): Promise<Events[]> {
  return getAuthorized(`${API}/jost/events`)
    .then(handleJsonResponse)
    .then((data) => data['events'])
    .catch(handleError)
}

export async function getReceipts({
  debit = false,
}: { debit?: boolean } = {}): Promise<Receipt[]> {
  const url = `${API}/prosync/receipts` + (debit ? '?debit=1' : '')
  return getAuthorized(url).then(handleJsonResponse).catch(handleError)
}

export async function getReceipt(id: string): Promise<Receipt> {
  return getAuthorized(`${API}/prosync/receipts/${id}`)
    .then(handleJsonResponse)
    .catch(handleError)
}

export async function getVouchers(): Promise<Voucher[]> {
  const url = `${API}/prosync/vouchers`
  return getAuthorized(url).then(handleJsonResponse).catch(handleError)
}

async function getAuthorized(url: string, options = {}) {
  const { clientId, password } = await storage.getCredentials()

  return get(url, {
    ...options,
    headers: {
      Authorization: 'Basic ' + window.btoa(`${clientId}:${password}`),
    },
  })
}

async function postAuthorized(url: string, data = {}, options = {}) {
  const { clientId, password } = await storage.getCredentials()

  return post(url, data, {
    ...options,
    headers: {
      Authorization: 'Basic ' + window.btoa(`${clientId}:${password}`),
    },
  })
}

async function get(url: string, options = {}) {
  return $fetch(url, {
    method: 'get',
    headers: { Accept: 'application/json' },
    ...options,
  })
}

async function post(
  url: string,
  data: {},
  options: { headers: {}; method?: string } = { headers: {} }
): Promise<Response> {
  return $fetch(url, {
    method: 'post',
    body: JSON.stringify(data),
    ...options,
    headers: {
      ...options.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

async function handleJsonResponse(res: Response) {
  if (res.ok) {
    try {
      const data = await res.json()
      return data
    } catch (err) {
      return Promise.reject({
        code: `EJSON${res.status}`,
        status: res.status,
      })
    }
  } else {
    return Promise.reject(errorFromResponse(res))
  }
}

async function handleError(err: {}) {
  return Promise.reject({ code: 'ENET', status: 0, ...err })
}

const $fetch: typeof fetch = (url, options) => {
  return fetch(url, options).then((res: Response) => {
    if (!res.ok) {
      console.error(res.url, res.status, res.statusText)
    }
    return res
  })
}

export async function errorFromResponse(
  res: Response
): Promise<{ code: string; status: number; errno?: string }> {
  // Default error used if no other error can be retrieved from API response
  // (e.g. when network error occurs)
  const defaultError = { code: 'EREMOTE', status: res.status }
  let error = defaultError

  // Try extracting specific `code` and `errno` if we received JSON from API
  if (res.headers.get('Content-Type')?.includes('application/json')) {
    try {
      error = { ...defaultError, ...(await res.json()) }
    } catch (err) {
      console.warn(
        "Non 2xx response is 'application/json' but JSON was invalid"
      )
    }
  }

  return error
}

export class Customer {
  customerCardNumber!: string
  dateOfBirth!: string
  iban!: string
  loyalityPoints!: number
  name1!: string
  firstName!: string
  lastName!: string
  street!: string
  zipCode!: string
  city!: string
  telephone!: string
  mobilePhone!: string
  email!: string
  number!: number
  subscriptionNewsletter!: boolean

  constructor(attributes: Customer) {
    Object.assign(this, attributes)
  }

  get mobilepayEnabled(): boolean {
    return !!this.iban
  }

  get mobilepayCard(): string {
    if (this.mobilepayEnabled && this.customerCardNumber.length) {
      return String(this.customerCardNumber).padEnd(12, '0')
    } else {
      return ''
    }
  }
}

export interface Page {
  slug: string
  title: string
  menu: boolean
  body: string
}

export interface News {
  title: string
  description: string
  image: string
}

export interface Events {
  title: string
  location: string
  date: string
  href: string
}

export interface Receipt {
  receiptNumber: number
  date: string
  time: string
  staff: string
  payment: string
  pendingDebit: boolean | null
  totalQuantity: number
  totalPrice: number
  positions: ReceiptPosition[]
}

export interface ReceiptPosition {
  articleNumber: number
  receiptPosition: number
  supplierArticleNumber: string
  quantity: number
  name: string
  size: string
  ean: number
  salePrice: number
  originalPrice: number
  priceDeduction: number // Absolute discount (money)
  priceDiscount: number // Relative discount (percentage)
}

export interface Voucher {
  customerNumber: number
  date: string
  number: number
  type: 'birthday' | 'bonus' | 'other'
  valid: boolean
  validFrom: string
  validUntil: string
  value: number
  visible: boolean
}

export interface UserConfig {
  vouchers: {
    count: number
    unread: boolean
    upcoming: boolean
  }
}
