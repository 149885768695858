import {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'
import { getPlatforms } from '@ionic/react'
import { App } from '@capacitor/app'
import { useAuth } from './auth'
import { getConfigCached, UserConfig } from '../api'

const defaultUserConfig: UserConfig = {
  vouchers: {
    count: 0,
    unread: false,
    upcoming: false,
  },
}

const userConfigContext = createContext<{
  userConfig: UserConfig
  setUserConfig: Dispatch<SetStateAction<UserConfig>>
  userConfigLoading: boolean
  clear: () => void
} | null>(null)

export function useUserConfig() {
  return useContext(userConfigContext)!
}

export function ProvideUserConfig({ children }: { children: ReactNode }) {
  const auth = useAuth()

  const [userConfig, setUserConfig] = useState<UserConfig>(defaultUserConfig)
  const [userConfigLoading, setUserConfigLoading] = useState<boolean>(true)

  const fetchConfig = async () => {
    setUserConfig(await getConfigCached())
    setUserConfigLoading(false)
  }

  const fetchConfigIfLoggedIn = useCallback(() => {
    if (auth.user) {
      fetchConfig()
    }
  }, [auth.user])

  useEffect(fetchConfigIfLoggedIn, [fetchConfigIfLoggedIn])
  useOnResume(() => {
    // After resume on native platforms, fetch even when cache timeout has not passed.
    if (getPlatforms().includes('capacitor')) {
      getConfigCached.clear()
    }

    fetchConfigIfLoggedIn()
  })

  const clear = () => {
    setUserConfig(defaultUserConfig)
  }

  return (
    <userConfigContext.Provider
      value={{ userConfig, setUserConfig, userConfigLoading, clear }}
    >
      {children}
    </userConfigContext.Provider>
  )
}

function useOnResume(handler: () => void) {
  useEffect(() => {
    // See https://capacitorjs.com/docs/apis/app#addlistenerresume-
    const resumeListenerHandle = App.addListener('resume', handler)
    return () => {
      resumeListenerHandle.then((h) => h.remove())
    }
  }, [handler])
}
