import {
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonList,
  IonItem,
  IonLabel,
} from '@ionic/react'
import { css } from '@emotion/css'
import { RemoteContent } from '../components/Content'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { getEvents, Events } from '../lib/api'
import { useRemote } from '../lib/hooks/remote'

const EventsPage: React.FC = () => {
  const { data: events, error, fetch } = useRemote<Events[]>(getEvents)

  return (
    <>
      <Menu />
      <IonPage id="main-content">
        <IonHeader>
          <Header menu={true} back={false}>
            Events
          </Header>
        </IonHeader>
        <IonContent fullscreen>
          <img src="/assets/images/header-events.jpg" alt="Events Header" />
          <div className="events">
            <IonList lines="full">
              <RemoteContent onRetry={fetch} error={error}>
                {events && events.length > 0 ? (
                  <>
                    {events.map((events) => (
                      <EventsItem events={events} key={events.title} />
                    ))}
                  </>
                ) : (
                  <div className="ion-padding-horizontal ion-padding-top empty">
                    <IonText>
                      Zur Zeit stehen leider noch keine Termine fest.
                    </IonText>
                  </div>
                )}
              </RemoteContent>
            </IonList>
          </div>
        </IonContent>
      </IonPage>
    </>
  )
}

const EventsItem: React.FC<{ events: Events }> = ({ events }) => {
  return (
    <IonItem
      className="events-item"
      href={'https://www.mode-jost.de/' + events.href}
      target="_blank"
    >
      <IonLabel>
        <h2>{events.title}</h2>
        <div
          className={css`
            padding-top: 5px;
          `}
        >
          <div className="location">{events.location}</div>
          <div className="date">{events.date}</div>
        </div>
      </IonLabel>
    </IonItem>
  )
}

export default EventsPage
