import {
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonButton,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonProgressBar,
  useIonPopover,
  IonBackButton,
} from '@ionic/react'
import { css } from '@emotion/css'
import { useAuth } from '../lib/providers/auth'
import { personCircleOutline, logOut, key, person } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLoading } from '../lib/providers/loading'
import { useUserConfig } from '../lib/providers/userConfig'

const Header: React.FC<{
  back?: boolean
  user?: boolean
  logo?: boolean
  menu?: boolean
}> = ({ children, back = true, user = true, logo = false, menu = false }) => {
  const history = useHistory()
  const auth = useAuth()
  const { clear: clearUserConfig } = useUserConfig()

  const [showProgress] = useProgressIndicator()

  const onLogout = async () => {
    await auth.logout()
    clearUserConfig() // TODO: Move to more central place
    history.replace('/login')
    dismiss()
  }

  const onChangePassword = () => {
    history.push('/password')
    dismiss()
  }

  const onMeMyJost = () => {
    history.push('/profile')
    dismiss()
  }

  const [present, dismiss] = useIonPopover(PopoverList, {
    onLogout,
    onChangePassword,
    onMeMyJost,
  })

  return (
    <IonToolbar>
      {showProgress && (
        <IonProgressBar
          type="indeterminate"
          color="primary"
          className={css`
            position: absolute;
            top: 0;
          `}
        ></IonProgressBar>
      )}
      <IonButtons slot="start">
        {menu && <IonMenuButton></IonMenuButton>}
        {back && <IonBackButton text="Zurück"></IonBackButton>}
      </IonButtons>
      <IonTitle>
        {logo ? (
          <img
            src="/assets/images/jost-logo.png"
            alt="Jost"
            className="header-logo"
          />
        ) : (
          children
        )}
      </IonTitle>
      <IonButtons slot="end">
        {user && (
          <IonButton
            onClick={(e) =>
              present({ event: e.nativeEvent, cssClass: 'user-popover' })
            }
          >
            <IonIcon icon={personCircleOutline}></IonIcon>
          </IonButton>
        )}
      </IonButtons>
    </IonToolbar>
  )
}

const PopoverList: React.FC<{
  onLogout: () => void
  onChangePassword: () => void
  onMeMyJost: () => void
}> = ({ onLogout, onChangePassword, onMeMyJost }) => {
  // NOTE: We cannot access the router here. To navigate, we need to pass events
  // to the parent component (which can access the router).
  return (
    <IonList lines="full">
      <IonItem onClick={onLogout}>
        <IonIcon icon={logOut} slot="start"></IonIcon>
        <IonLabel>Abmelden</IonLabel>
      </IonItem>
      <IonItem onClick={onMeMyJost} detail={true}>
        <IonIcon icon={person} slot="start"></IonIcon>
        <IonLabel>Persönliche Daten</IonLabel>
      </IonItem>
      <IonItem onClick={onChangePassword} detail={true} lines="none">
        <IonIcon icon={key} slot="start"></IonIcon>
        <IonLabel>Kennwort ändern</IonLabel>
      </IonItem>
    </IonList>
  )
}

function useProgressIndicator({ minWait = 2000 }: { minWait?: number } = {}) {
  const [{ loading }] = useLoading()
  const [showProgress, setShowProgress] = useState(false)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (loading) {
      timeoutId = setTimeout(() => setShowProgress(true), minWait)
    } else {
      setShowProgress(false)
    }

    return function () {
      clearTimeout(timeoutId)
    }
  }, [loading, setShowProgress, minWait])

  return [showProgress, setShowProgress]
}

export default Header
