import { css } from '@emotion/css'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import Barcode from '../components/Barcode'
import { MobilepayDisabled, RemoteContent } from '../components/Content'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { getMe, Customer } from '../lib/api'
import { useRemote } from '../lib/hooks/remote'

const MobilepayPage: React.FC = () => {
  const { data: customer, error, fetch } = useRemote<Customer>(getMe)
  return (
    <>
      <Menu />
      <IonPage id="main-content">
        <IonHeader>
          <Header menu={true} back={false}>
            MobilePAY
          </Header>
        </IonHeader>
        <IonContent fullscreen className="ion-padding">
          <RemoteContent onRetry={fetch} error={error}>
            {customer && (
              <>
                {customer.mobilepayEnabled ? (
                  <>
                    <div
                      className={css`
                        margin-top: 1rem;

                        @media (min-height: 610px) {
                          margin-top: 3rem;
                        }
                      `}
                    >
                      <Barcode value={customer.mobilepayCard} />
                    </div>
                    <div
                      className={css`
                        margin-top: 3rem;

                        h1 {
                          color: inherit;
                          text-transform: none;
                        }
                      `}
                    >
                      <h1>
                        Jetzt <strong>bargeldlos, bequem</strong> und&nbsp;
                        <strong>sicher</strong> zahlen mit{' '}
                        <strong>MobilePAY</strong>
                      </h1>
                    </div>
                    <div>
                      <p>
                        Sie nutzen bereits unseren Auswahl-Service und wissen
                        diesen zu schätzen – jetzt steht Ihnen zusätzlich
                        MobilePAY zur Verfügung.
                      </p>
                      <p>
                        Zeigen Sie einfach Ihre Jost App an der Kasse vor. Der
                        Kaufbetrag wird Ihnen erst nach 10 Tagen von Ihrem Konto
                        abgebucht. Ohne weitere Kosten oder versteckte Gebühren!
                      </p>
                    </div>
                  </>
                ) : (
                  <MobilepayDisabled />
                )}
              </>
            )}
          </RemoteContent>
        </IonContent>
      </IonPage>
    </>
  )
}

export default MobilepayPage
