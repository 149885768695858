import { ReactNode } from 'react'
import { IonRouterOutlet, IonTabs } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Redirect, Route } from 'react-router-dom'
import { IonIcon, IonLabel, IonTabBar, IonTabButton } from '@ionic/react'
import { ProtectedRoute, useAuth } from './lib/providers/auth'
import { VoucherBadge } from './components/VoucherBadge'
import {
  newspaperOutline,
  heartOutline,
  barcodeOutline,
  cardOutline,
  cartOutline,
  calendarOutline,
} from 'ionicons/icons'

/* Pages */
import Placeholder from './pages/Placeholder'
import NewsPage from './pages/News'
import MyJostPage from './pages/MyJost'
import ProfilePage from './pages/Profile'
import BonuspointsMyJostPage from './pages/myjost/Bonuspoints'
import {
  MobilepayTrialsPage,
  MobilePayTrialsShowPage,
} from './pages/myjost/MobilepayTrials'
import {
  ReceiptsMyJostPage,
  ReceiptsMyJostShowPage,
} from './pages/myjost/Receipts'
import { TrialsMyJostPage, TrialsMyJostShowPage } from './pages/myjost/Trials'
import VouchersMyJostPage from './pages/myjost/Vouchers'
import BarcodePage from './pages/Barcode'
import MobilepayPage from './pages/Mobilepay'
import VouchersPage from './pages/Voucher'
import EventsPage from './pages/Events'
import StaticPage from './pages/Static'
import LoginPage from './pages/auth/Login'
import ActivatePage from './pages/auth/Activate'
import PasswordPage from './pages/Password'
import { RoutedApp } from './App'

// Conditionally wrap routes (given as children) in tabs. This is to workaround
// broken route animations. Ionic does not accept child elements of
// `<IonRouterOutler />` to be wrapped in a fragment (routes must be immediate
// children).
const TabRouter: React.FC<{ showTabs: boolean; tabBar: ReactNode }> = ({
  showTabs,
  tabBar,
  children,
}) => {
  return (
    <IonReactRouter>
      {showTabs ? (
        <IonTabs>
          <IonRouterOutlet>{children}</IonRouterOutlet>
          {tabBar}
        </IonTabs>
      ) : (
        <IonRouterOutlet>{children}</IonRouterOutlet>
      )}
      {/* Must be here for access to history provided by <IonReactRouter/> component */}
      <RoutedApp />
    </IonReactRouter>
  )
}

const Router: React.FC = () => {
  const auth = useAuth()

  const tabBar = (
    <IonTabBar slot="bottom">
      <IonTabButton tab="home" href="/news">
        <IonIcon icon={newspaperOutline} />
        <IonLabel>Aktuelles</IonLabel>
      </IonTabButton>
      <IonTabButton tab="myjost" href="/myjost">
        <IonIcon icon={heartOutline} />
        <IonLabel>myJost</IonLabel>
        <VoucherBadge />
      </IonTabButton>
      <IonTabButton tab="barcode" href="/barcode">
        <IonIcon icon={barcodeOutline} />
        <IonLabel>Kundenkarte</IonLabel>
      </IonTabButton>
      <IonTabButton tab="mobilepay" href="/mobilepay">
        <IonIcon icon={cardOutline} />
        <IonLabel>MobilePAY</IonLabel>
      </IonTabButton>
      <IonTabButton tab="events" href="/events">
        <IonIcon icon={calendarOutline} />
        <IonLabel>Events</IonLabel>
      </IonTabButton>
      <IonTabButton tab="vouchers" href="/vouchers">
        <IonIcon icon={cartOutline} />
        <IonLabel>Gutscheine</IonLabel>
      </IonTabButton>
    </IonTabBar>
  )

  // Before applying routes, wait until we know if the user is logged in
  // (depends on async read from storage). This prevents undesired flashes of
  // the login screen and simplifies routing (no need to redirect back after
  // login).
  return auth.initialized ? (
    <TabRouter {...{ showTabs: !!auth.user, tabBar }}>
      <ProtectedRoute path="/news" exact>
        <NewsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/myjost" exact>
        <MyJostPage />
      </ProtectedRoute>
      <Route path="/password" exact>
        <PasswordPage change={true} />
      </Route>
      <ProtectedRoute path="/profile" exact>
        <ProfilePage />
      </ProtectedRoute>
      <ProtectedRoute path="/myjost/bonuspoints" exact>
        <BonuspointsMyJostPage />
      </ProtectedRoute>
      <ProtectedRoute path="/myjost/receipts" exact>
        <ReceiptsMyJostPage />
      </ProtectedRoute>
      <ProtectedRoute path="/myjost/receipts/:id" exact>
        <ReceiptsMyJostShowPage />
      </ProtectedRoute>
      <ProtectedRoute path="/myjost/trials" exact>
        <TrialsMyJostPage />
      </ProtectedRoute>
      <ProtectedRoute path="/myjost/trials/:id" exact>
        <TrialsMyJostShowPage />
      </ProtectedRoute>
      <ProtectedRoute path="/myjost/vouchers" exact>
        <VouchersMyJostPage />
      </ProtectedRoute>
      <ProtectedRoute path="/myjost/mobilepay" exact>
        <MobilepayTrialsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/myjost/mobilepay/:id" exact>
        <MobilePayTrialsShowPage />
      </ProtectedRoute>
      <ProtectedRoute path="/barcode" exact>
        <BarcodePage />
      </ProtectedRoute>
      <ProtectedRoute path="/mobilepay" exact>
        <MobilepayPage />
      </ProtectedRoute>
      <ProtectedRoute path="/vouchers" exact>
        <VouchersPage />
      </ProtectedRoute>
      <ProtectedRoute path="/events" exact>
        <EventsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/pages/:slug" exact>
        <StaticPage />
      </ProtectedRoute>
      <Route path="/login" exact>
        {auth.user ? <Redirect to="/" /> : <LoginPage />}
      </Route>
      <Route path="/activate" exact>
        {auth.user ? <Redirect to="/" /> : <ActivatePage />}
      </Route>
      <Route path="/reset-password" exact>
        <PasswordPage />
      </Route>
      <Route path="/" exact>
        <Redirect to="/news" />
      </Route>
    </TabRouter>
  ) : (
    // Mitigate visual flashes by painting parts of eventual page, e.g. Header.
    <Placeholder />
  )
}

export default Router
