import dayjs from 'dayjs'

// https://stackoverflow.com/a/39914235
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function toDate(date: string, format: string = 'DD.MM.YYYY'): string {
  return dayjs(date).format(format)
}

export function toEuro(cents: number): string {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  })
  return formatter.format(cents)
}
