import {
  IonButton,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonIcon,
} from '@ionic/react'
import { KeyboardEventHandler, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import pick from 'lodash/pick'
import { pencilOutline } from 'ionicons/icons'
import { postActivate, errorFromResponse } from '../../lib/api'
import { descriptiveActivationError, reviewError } from '../../lib/errors'
import { useLoading } from '../../lib/providers/loading'
import { useAlert } from '../../lib/hooks/alert'
import Header from '../../components/Header'
import { Callout, Warning } from '../../components/Callout'
import CustomerForm, { CustomerInputs } from '../../components/CustomerForm'
import PasswordInput from '../../components/inputs/PasswordInput'
import ToggleInput from '../../components/inputs/ToggleInput'
import { ContactSupport, RemoteButton } from '../../components/Content'
import { css } from '@emotion/css'

interface PasswordInputs {
  generatePassword: boolean
  password?: string
  passwordConfirmation?: string
}

type ActivateInputs = CustomerInputs & PasswordInputs

interface ActivateError {
  code: string
  message: string
}

enum FormStep {
  Client = 1,
  Password,
}

const ActivatePage: React.FC = () => {
  const defaultInputs: ActivateInputs = {
    number: '',
    firstName: '',
    lastName: '',
    email: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    advertisement: false,
    generatePassword: true,
    password: '',
    passwordConfirmation: '',
  }

  const [step, setStep] = useState(FormStep.Client)
  const [error, setError] = useState<ActivateError | null>(null)
  const [activateInputs, setActivateInputs] =
    useState<ActivateInputs>(defaultInputs)
  const history = useHistory()
  const [alert] = useAlert()
  const [, { start, stop }] = useLoading()

  const onContinueClient = (newInputs: CustomerInputs) => {
    const inputs = {
      ...activateInputs,
      ...newInputs,
    }
    setActivateInputs(inputs)
    setStep(FormStep.Password)
  }

  const onContinuePassword = (newInputs: PasswordInputs) => {
    const inputs = {
      ...activateInputs,
      ...newInputs,
    }
    setActivateInputs(inputs)
    onSubmit(inputs)
  }

  const onSubmit: SubmitHandler<ActivateInputs> = async (data) => {
    const { number: clientId } = data
    start()
    const res = await postActivate(clientId, normalize(data))

    if (res.ok) {
      stop({ success: true })
      history.replace(`/login?clientId=${clientId}`)
      alert('Registrierung abgeschlossen.')
    } else {
      const error = await errorFromResponse(res)
      stop({ success: false })
      console.error(error)
      setError({
        code: error.errno ? `${error.errno}/${error.code}` : error.code,
        message: descriptiveActivationError(error.code, error.status),
      })
      setStep(FormStep.Client)
    }

    function normalize(data: ActivateInputs) {
      let submitData: {
        customer: ActivateInputs & { dateOfBirth: string }
        password?: string
      } = {
        customer: {
          ...data,
          dateOfBirth:
            dayjs(
              `${data.birthYear}-${data.birthMonth}-${data.birthDay}`
            ).format('YYYY-MM-DD') + 'T00:00:00',
        },
      }

      if (data.generatePassword === false) {
        submitData = { ...submitData, password: data.password }
      }

      return submitData
    }
  }

  const onError: SubmitErrorHandler<ActivateInputs> = (errors) => {
    console.error(errors)
    alert(reviewError)
  }

  const onBack = () => {
    setStep(step - 1)
  }

  return (
    <IonPage>
      <IonHeader>
        <Header back={false} logo={true} user={false}></Header>
      </IonHeader>
      <IonContent fullscreen>
        <ActivateForm
          hidden={step !== FormStep.Client}
          values={activateInputs}
          onContinue={onContinueClient}
          onError={onError}
        >
          {error && (
            <div className="ion-padding-top">
              {error.code !== 'EPASWEX' ? (
                <>
                  <Warning>{error.message}</Warning>
                  <p>
                    <small>
                      <ContactSupport
                        action="die Aktivierung"
                        review={error.code !== 'EREMOTE'}
                        error={error}
                      />
                    </small>
                  </p>
                </>
              ) : (
                <>
                  <Callout>{error.message}</Callout>
                  <IonButton
                    fill="outline"
                    expand="block"
                    size="small"
                    routerLink="/reset-password"
                    className={
                      'secondary ' +
                      css`
                        margin-top: var(--ion-padding);
                        margin-bottom: calc(var(--ion-padding) * 2);
                      `
                    }
                  >
                    Kennwort zurücksetzen
                  </IonButton>
                </>
              )}
            </div>
          )}
        </ActivateForm>
        <ActivatePasswordForm
          hidden={step !== FormStep.Password}
          values={activateInputs}
          onContinue={onContinuePassword}
          onError={onError}
          onBack={onBack}
        ></ActivatePasswordForm>
      </IonContent>
    </IonPage>
  )
}

const ActivateForm: React.FC<{
  hidden: boolean
  values: CustomerInputs
  onContinue: SubmitHandler<CustomerInputs>
  onError: SubmitErrorHandler<CustomerInputs>
}> = ({ children, hidden = false, values, onContinue, onError }) => {
  return (
    <>
      <CustomerForm
        {...{ hidden, values, onSubmit: onContinue, onError, header: children }}
      >
        <IonButton
          expand="block"
          fill="outline"
          type="submit"
          className="secondary mt-2"
        >
          Weiter
        </IonButton>
        <Link to="/login" className="alternate-action">
          Abbrechen
        </Link>
      </CustomerForm>
    </>
  )
}

const ActivatePasswordForm: React.FC<{
  hidden: boolean
  values: ActivateInputs
  onContinue: SubmitHandler<PasswordInputs>
  onError: SubmitErrorHandler<PasswordInputs>
  onBack: () => void
}> = ({ hidden = false, values, onContinue, onError, onBack }) => {
  const { control, getValues, watch, handleSubmit } = useForm<PasswordInputs>({
    defaultValues: pick(values, [
      'generatePassword',
      'password',
      'passwordConfirmation',
    ]),
    criteriaMode: 'all',
  })

  const watchGeneratePassword = watch('generatePassword')

  const formattedDateOfBirth = dayjs(
    `${values.birthYear}-${values.birthMonth}-${values.birthDay}`
  ).format('DD.MM.YYYY')

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onContinue, onError)()
    }
  }

  return (
    <form
      hidden={hidden}
      className="ion-padding"
      onSubmit={handleSubmit(onContinue, onError)}
      onKeyDown={handleKeyDown}
      noValidate
    >
      <div className="ion-padding-vertical">
        <Callout>
          Ist Ihre E-Mail Adresse korrekt? Ihre Zugangsdaten senden wir Ihnen
          per E-Mail.
        </Callout>
      </div>

      <table className="table col-2">
        <tbody>
          <tr>
            <td className="label">Name</td>
            <td>
              {values.firstName} {values.lastName}
            </td>
          </tr>
          <tr>
            <td className="label">E-Mail</td>
            <td>
              <strong>{values.email}</strong>
              <br />
            </td>
          </tr>
          <tr>
            <td className="label">Kundennummer</td>
            <td>{values.number}</td>
          </tr>
          <tr>
            <td className="label">Geburtsdatum</td>
            <td>{formattedDateOfBirth}</td>
          </tr>
        </tbody>
      </table>

      <IonButton
        fill="outline"
        expand="block"
        className="secondary"
        onClick={onBack}
      >
        <IonIcon slot="start" icon={pencilOutline}></IonIcon>
        Bearbeiten
      </IonButton>
      <IonList lines="full" className="mt-2">
        <ToggleInput<PasswordInputs>
          {...{ control }}
          name="generatePassword"
          label="Zufälliges Kennwort"
        ></ToggleInput>
        {watchGeneratePassword === false && (
          <PasswordInput<PasswordInputs>
            {...{ control, getValues }}
            rules={{
              required: 'Kennwort benötigt',
              minLength: {
                value: 6,
                message: 'Kennwort zu kurz (mindestens 6 Zeichen)',
              },
            }}
            name="password"
            label="Kennwort"
            confirmation={true}
          ></PasswordInput>
        )}
        <RemoteButton expand="block" type="submit" className="primary mt-1">
          Aktivieren
        </RemoteButton>
      </IonList>
    </form>
  )
}

export default ActivatePage
