import {
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonButton,
} from '@ionic/react'
import Header from '../components/Header'

const VoucherPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <Header menu={true} back={false}>
          Voucher
        </Header>
      </IonHeader>
      <IonContent fullscreen>
        <img src="/assets/images/header-vouchers.jpg" alt="Gutscheine" />
        <div className="ion-padding-horizontal ion-padding-top">
          <IonText>
            Das besondere Geschenk mit Stil. Überraschen Sie einen lieben
            Menschen mit einem Geschenkgutschein von Mode Jost. Vom Rock bis zur
            Bluse, von den Socken bis zur Krawatte, vom T-Shirt bis zu
            hochwertigen Pullovern. Versenden Sie den Geschenkgutschein direkt
            per E-Mail oder drucken Sie diesen aus, um das Geschenk persönlich
            zu überreichen.
          </IonText>
        </div>
        <div className="mt-1 ion-padding-horizontal">
          <IonButton
            href="https://gutschein.mode-jost.de/"
            target="_blank"
            expand="block"
          >
            Jetzt zum Gutscheinportal wechseln
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default VoucherPage
