import { useEffect, useRef, useState } from 'react'
import JsBarcode, { Options } from 'jsbarcode'
import { css } from '@emotion/css'
import { useDoubleTap } from 'use-double-tap'
import { InnerMessage } from './Content'

// https://github.com/lindell/JsBarcode/wiki/Options
const barcodeOptions: Options = {
  format: 'CODE128',
  width: 3,
  lineColor: '#293d54',
  displayValue: false,
}

const Barcode: React.FC<{ value: string }> = ({ value }) => {
  const [error, setError] = useState<boolean>(false)
  const [displayValue, setDisplayValue] = useState<boolean>(
    barcodeOptions.displayValue!
  )
  const svg = useRef<SVGSVGElement>(null)

  useEffect(() => {
    if (!value) {
      console.log(`Invalid barcode value ${value}`)
      setError(true)
      return
    }

    try {
      JsBarcode(svg.current, value, { ...barcodeOptions, displayValue })
      setError(false)
    } catch (err) {
      console.warn(`Could not render barcode value ${value}`)
      console.error(err)
      setError(true)
    }
  }, [displayValue, svg, value])

  const { onClick: onDoubleTap } = useDoubleTap((event) => {
    if (event.shiftKey) {
      setDisplayValue(!displayValue)
    }
  }, 1000)

  return (
    <div
      onClick={onDoubleTap}
      className={css`
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        min-height: 120px;

        svg {
          position: static;
          display: ${error ? 'none' : 'block'};
          margin: 0 auto;
          max-width: 90vw; // Ensure barcode image does not overflow viewport
        }
      `}
    >
      <svg ref={svg}></svg>
      {error && (
        <InnerMessage>
          <small>Barcode ({value}) ungültig.</small>
        </InnerMessage>
      )}
    </div>
  )
}

export default Barcode
