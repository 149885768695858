import { IonPage, IonHeader, IonContent } from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { css } from '@emotion/css'
import { RemoteContent } from '../../components/Content'
import { ReceiptDetail, ReceiptList } from '../../components/Receipts'
import Header from '../../components/Header'
import { getReceipt, getReceipts, Receipt } from '../../lib/api'
import { useRemote } from '../../lib/hooks/remote'
import { toDate } from '../../lib/utils'

export const ReceiptsMyJostPage: React.FC = () => {
  const fetchReceipts = useCallback(() => {
    return getReceipts({ debit: false })
  }, [])

  const { data: receipts, error, fetch } = useRemote<Receipt[]>(fetchReceipts)

  return (
    <IonPage>
      <IonHeader>
        <Header>Meine Einkäufe</Header>
      </IonHeader>
      <IonContent fullscreen>
        <div
          className={
            'ion-padding ' +
            css`
              h1:first-child {
                margin-top: 0;
              }
            `
          }
        >
          <h1>Meine Einkäufe</h1>
          <p>
            Sie können den Originalbeleg nicht mehr finden oder haben diesen
            bereits entsorgt? Kein Problem! Alle Belege Ihrer Einkäufe der
            letzten 12 Monate finden Sie nachfolgend. Rückgaben ohne
            Originalbeleg sind damit ab sofort möglich.
          </p>
        </div>
        <RemoteContent onRetry={fetch} error={error}>
          {receipts && (
            <ReceiptList
              receipts={receipts}
              itemLink={(receipt) =>
                `/myjost/receipts/${receipt.receiptNumber}`
              }
            />
          )}
        </RemoteContent>
      </IonContent>
    </IonPage>
  )
}

export const ReceiptsMyJostShowPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const fetchReceipt = useCallback(() => {
    return getReceipt(id)
  }, [id])

  const { data: receipt, error, fetch } = useRemote<Receipt>(fetchReceipt)

  return (
    <IonPage>
      <IonHeader>
        <Header>
          {receipt && `Einkauf vom ${toDate(receipt.date, 'DD.MM.')}`}
        </Header>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <RemoteContent onRetry={fetch} error={error}>
          {receipt && (
            <ReceiptDetail
              receipt={receipt}
              label="Bon"
              barcode={true}
              fields={{
                time: true,
                totalQuantity: true,
                payment: true,
                staff: true,
              }}
            />
          )}
        </RemoteContent>
      </IonContent>
    </IonPage>
  )
}
