import { IonPage, IonHeader, IonContent } from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/css'
import { RemoteContent } from '../../components/Content'
import { ReceiptDetail, ReceiptList } from '../../components/Receipts'
import Header from '../../components/Header'
import { getReceipt, getReceipts, Receipt } from '../../lib/api'
import { useRemote } from '../../lib/hooks/remote'
import { toDate } from '../../lib/utils'

export const TrialsMyJostPage: React.FC = () => {
  const fetchTrials = useCallback(() => {
    return getReceipts({ debit: true })
  }, [])

  const { data: receipts, error, fetch } = useRemote<Receipt[]>(fetchTrials)
  const [trialReceipts, setTrialReceipts] = useState<Receipt[]>([])

  useEffect(() => {
    if (!receipts) {
      return
    }

    const trialReceipts = receipts
      .filter((r) => r.payment === 'Jost - Auswahl')
      .filter((r) => r.pendingDebit)

    setTrialReceipts(trialReceipts)
  }, [receipts])

  return (
    <IonPage>
      <IonHeader>
        <Header>Offene Auswahlen</Header>
      </IonHeader>
      <IonContent fullscreen>
        <div
          className={
            'ion-padding ' +
            css`
              h1:first-child {
                margin-top: 0;
              }
            `
          }
        >
          <h1>Offene Auswahlen</h1>
          <p>
            Sie möchten aktuelle Ware lieber zu Hause probieren? Dann stellen
            Sie sich eine Auswahl zusammen und nehmen sie mit. Sie wählen in
            aller Ruhe aus &ndash; was Sie behalten, bekommen Sie nach 10 Tagen
            bequem von Ihrem Konto abgebucht.
          </p>
        </div>
        <RemoteContent onRetry={fetch} error={error}>
          {trialReceipts && (
            <ReceiptList
              receipts={trialReceipts}
              itemLink={(receipt) => `/myjost/trials/${receipt.receiptNumber}`}
              sum={true}
              notFoundMessage="Derzeit haben Sie keine offenen Auswahlen."
            />
          )}
        </RemoteContent>
      </IonContent>
    </IonPage>
  )
}

export const TrialsMyJostShowPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const fetchReceipt = useCallback(() => {
    return getReceipt(id)
  }, [id])

  const { data: receipt, error, fetch } = useRemote<Receipt>(fetchReceipt)

  return (
    <IonPage>
      <IonHeader>
        <Header>
          {receipt && `Auswahl vom ${toDate(receipt.date, 'DD.MM.')}`}
        </Header>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <RemoteContent onRetry={fetch} error={error}>
          {receipt && (
            <ReceiptDetail receipt={receipt} label="Auswahl" barcode={true} />
          )}
        </RemoteContent>
      </IonContent>
    </IonPage>
  )
}
